<template>
  <div class="main">
    <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
    <section class="section">
      <div class="row">
        <div class="col">
          <Panel type="sheet">
            <template v-slot:headline>メニュー登録</template>
            <template v-slot:body>
              <div class="form">
                <section class="form-section">
                  <FormRow :required="true">
                    <template v-slot:label>カテゴリー選択</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <div class="form-select">
                          <select
                            class="form-control form-select-input"
                            :class="{ 'is-error': errors['categoryId'] }"
                            name="categoryId"
                            v-model="formData.categoryId"
                          >
                            <option value="">選択してください</option>
                            <option
                              v-for="item in categories"
                              :key="item.id"
                              :value="item.id"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>メニュー名</template>
                    <template v-slot:labelNote>（30文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          class="form-control"
                          :class="{ 'is-error': errors['name'] }"
                          type="text"
                          name="name"
                          v-trim
                          v-maxlength
                          maxlength="30"
                          v-model="formData.name"
                        />
                      </div>
                    </template>
                  </FormRow>
                  <FormRow :required="true">
                    <template v-slot:label>写真をアップロード</template>
                    <template v-slot:subLabel>画像推奨サイズ：270px × 270px</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <ul class="fileUpload">
                          <li class="fileUpload-item">
                            <Fileupload
                              @uploaded="uploadImage"
                              @removed="deleteImage"
                              :initialUrl="initialImage"
                              :loading="isUploadLoading"
                              :isError="errors['image']"
                            />
                          </li>
                        </ul>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>コメント</template>
                    <template v-slot:labelNote>（100文字以内）</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <textarea
                          class="form-control form-textarea"
                          :class="{ 'is-error': errors['comment'] }"
                          type="text"
                          name="comment"
                          v-trim
                          v-maxlength
                          maxlength="100"
                          v-model="formData.comment"
                        ></textarea>
                      </div>
                    </template>
                  </FormRow>
                  <FormRow>
                    <template v-slot:label>料金表示</template>
                    <template v-slot:content>
                      <div class="form-content-row">
                        <input
                          class="form-control"
                          :class="{ 'is-error': errors['description'] }"
                          type="text"
                          name="description"
                          v-trim
                          v-maxlength
                          maxlength="60"
                          v-model="formData.description"
                        />
                      </div>
                      <div class="form-content-row">
                        <div class="form-group">
                          <div class="form-group-item">
                            <input
                              :class="{ 'form-control': true, 'is-error': errors['cost'] }"
                              type="tel"
                              name="cost"
                              v-maxlength
                              maxlength="7"
                              v-number="'integer'"
                              v-model="formData.cost"
                            />
                          </div>
                          <div class="form-group-item">円（税込）</div>
                        </div>
                      </div>
                      <div class="form-content-row">
                        <p>
                          金額のみの表示の場合は下段に数値のみ記載します。<br/>
                          上段は金額の前にテキストを追加したりする際、登録します。<br/>
                          上段・下段共にそれぞれ空欄も可能ですが、最後に円（税込）が記載されます。
                        </p>
                      </div>
                    </template>
                  </FormRow>
                </section>
              </div>
            </template>
          </Panel>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, maxLength } from 'vuelidate/lib/validators';
import nav from '@/mixins/nav/menu';
import error from '@/mixins/plugin/error';
//component
import FormRow from '@/components/FormRow.vue';
import Fileupload from '@/components/Fileupload.vue';
export default {
  data: function() {
    return {
      formData: {
        categoryId: '',
        name: '',
        image: '',
        comment: '',
        description: '',
        cost: null,
      },
      initialImage: '',
      isUploadLoading: false,
    };
  },
  props: {
    pageName: {
      type: String,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [nav, error],
  validations: {
    formData: {
      categoryId: { selectRequired: required },
      name: { required, maxLength: maxLength(30) },
      image: { selectRequired: required },
      comment: { maxLength: maxLength(100) },
      description: { maxLength: maxLength(60) },
      cost: { maxLength: maxLength(7) },
    },
  },
  components: {
    FormRow,
    Fileupload,
  },
  computed: {
    ...mapGetters({
      categories: 'menu/categoryList',
      menuDetail: 'menu/menuDetail',
      userInfo: 'auth/infor',
      subdomain: 'auth/commonSubdomain',
      shopId: 'auth/commonShopId',
    }),
    id() {
      return this.$route.params?.id;
    },
    formatData() {
      return {
        categoryId: this.formData.categoryId,
        name: this.formData.name,
        image: this.formData.image,
        comment: this.formData.comment,
        description: this.formData.description,
        cost: this.formData.cost,
      };
    },
  },
  methods: {
    handleSubmit: async function() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.formData, 'menu');
      } else {
        let result;
        if (!this.isEdit) {
          result = await this.$store.dispatch('menu/createMenu', {
            subdomain: this.subdomain,
            shopId: this.shopId,
            data: this.formatData,
          });
          if (result) {
            await this.$router.push({ name: 'ShopMenu' });
            this.$message.created('menu');
          }
        } else {
          result = await this.$store.dispatch('menu/updateMenu', {
            subdomain: this.subdomain,
            shopId: this.shopId,
            data: this.formatData,
            id: this.id,
          });
          if (result) {
            await this.$router.push({ name: 'ShopMenu' });
            this.$message.updated('menu');
          }
        }
      }
    },
    async uploadImage(url) {
      this.isUploadLoading = true;
      this.formData.image = await this.$store.dispatch('common/uploadImage', {
        image: url,
      });
      this.isUploadLoading = false;
    },
    deleteImage() {
      this.formData.image = '';
    },
    async getDetail() {
      if (this.isEdit) {
        await this.$store.dispatch('menu/getMenuDetail', {
          subdomain: this.subdomain,
          shopId: this.shopId,
          id: this.id,
        });
      }
    },
    async getCategories() {
      await this.$store.dispatch('menu/getCategories', {
        subdomain: this.subdomain,
        shopId: this.shopId,
      });
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([this.getCategories(), this.getDetail()]).finally(() => {
      if (this.isEdit) {
        this.initialImage = this.menuDetail.image.url;
        this.formData = {
          ...this.formData,
          ...this.menuDetail,
          image: this.menuDetail.image.id,
          categoryId: this.menuDetail.category.id,
        };
      }
      this.$loading.clear(loading);
    });
  },
};
</script>

<style></style>
